import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Container, Row, Button } from 'react-bootstrap';

export const Camping = () => {  
  const folders = ["North","South"];

  return (
    <section className="imagepg camping snapScrolling" id="photobg">
      <Container>
        <Row>
          <TrackVisibility>
            {({ isVisible }) => (
              <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                <h1>Camping</h1>
                <h2>Find your new favorite campsites around Burns Lake!</h2>
                <div className="imagepg-buttons">
                  {folders.map((folderName) => {
                    const buttonStyle = {
                      borderRadius: "50%",
                      width: "300px",
                      height: "300px",
                      paddingTop: "130px",
                      margin: "10px",
                      background: `url(${require(`../assets/img/campsites/${folderName}.jpg`)})`,
                      backgroundSize: "cover",
                      border: "none",
                      fontSize: "30px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "white",
                      textShadow: "-1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black, 0px 1px 0px black",
                      transition: "color 0.1s, text-shadow 0.1s",
                    };
                    const buttonHoverStyle = {
                      color: "black",
                      textShadow: "-1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white, 0px 1px 0px white",
                    };
                    return (
                      <Button
                        href={`/Camping/${folderName.toLowerCase()}`}
                        key={folderName}
                        style={buttonStyle}
                        onMouseEnter={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                        onMouseLeave={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                      >
                        {folderName}
                      </Button>
                    );
                  })}
                </div>
              </div>  
            )}
          </TrackVisibility>
        </Row>
      </Container>
    </section>
  );
};