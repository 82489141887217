import React, { useState, useEffect } from 'react';
import { Carousel, Button, Row, Col, Container } from 'react-bootstrap';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import { CampsiteStatus } from "./CampsiteStatus.js";
import { CampsiteInfo } from './CampsiteInfo.js';
import campsiteData from './campsiteData.json';

//Import all images
function ImagePage({ item }) {
  //Title of item being rendered
  const itemName = item.split('/').pop().replaceAll("-"," ").split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  
  //Set buttons state true when need to render subfolders
  const [buttons, setButtons] = useState(false);

  //FULL SCREEN BUTTON
  const [fullscreen, setFullscreen] = useState(false);
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  //Import all images from require.context
  function importAll(r) {
    let images = [];
    r.keys().forEach((item, index) => {
      const file = r(item);
      images.push({ 
        name: item.replace('./', ''), 
        file: file 
      });
      //console.log(`Pushing item ${index} to images: `, { name: item.replace('./', ''), file });
    });
    return images;
  }

  //Import all info on Campsites Here
  const toCamelCase = (str) => {
    return str
      .split('/')
      .map(part => part.split('-').map(subpart => subpart.charAt(0).toUpperCase() + subpart.slice(1)).join('-'))
      .join('/');
  };
  const normalizedItem = toCamelCase(item);

  const campsiteInfo = campsiteData[normalizedItem] || { statusItems: [], infoItems: [] };
  const infoItems = campsiteInfo.infoItems;
  const statusItems = campsiteInfo.statusItems;

  //Switch Page based on Item passed in
  let images = null;

  switch (`${item}`) {
    //Direction landing Pages
    case "north":
      images = importAll(require.context("../assets/img/campsites/North", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south":
      images = importAll(require.context("../assets/img/campsites/South", false, /\.(png|jpe?g|svg)$/));
      break;

    //North
    case "north/kager":
      images = importAll(require.context("../assets/img/campsites/North/Kager", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/division":
      images = importAll(require.context("../assets/img/campsites/North/Division", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/pinkut":
      images = importAll(require.context("../assets/img/campsites/North/Pinkut", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/ethel-f-wilson":
      images = importAll(require.context("../assets/img/campsites/North/Ethel F Wilson", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/augier":
      images = importAll(require.context("../assets/img/campsites/North/Augier", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/pendleton-bay":
      images = importAll(require.context("../assets/img/campsites/North/Pendleton Bay", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/taltapin":
      images = importAll(require.context("../assets/img/campsites/North/Taltapin", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/coop":
      images = importAll(require.context("../assets/img/campsites/North/Coop", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/richmond":
      images = importAll(require.context("../assets/img/campsites/North/Richmond", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/maxan":
      images = importAll(require.context("../assets/img/campsites/North/Maxan", false, /\.(png|jpe?g|svg)$/));
      break;  
    
    //South
    case "south/agate-point":
      images = importAll(require.context("../assets/img/campsites/South/Agate Point", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/mclure-pit":
      images = importAll(require.context("../assets/img/campsites/South/Mclure Pit", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/govt-point":
      images = importAll(require.context("../assets/img/campsites/South/Govt Point", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/colleymount":
      images = importAll(require.context("../assets/img/campsites/South/Colleymount", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/noralee":
      images = importAll(require.context("../assets/img/campsites/South/Noralee", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/noralee/noralee-east":
      images = importAll(require.context("../assets/img/campsites/South/Noralee/Noralee East", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/noralee/noralee-west":
      images = importAll(require.context("../assets/img/campsites/South/Noralee/Noralee West", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/west-francois":
      images = importAll(require.context("../assets/img/campsites/South/West Francois", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/lund":
      images = importAll(require.context("../assets/img/campsites/South/Lund", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/wistaria":
      images = importAll(require.context("../assets/img/campsites/South/Wistaria", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/ootsa":
      images = importAll(require.context("../assets/img/campsites/South/Ootsa", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/takysie":
      images = importAll(require.context("../assets/img/campsites/South/Takysie", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/takysie/takysie-site-a":
      images = importAll(require.context("../assets/img/campsites/South/Takysie/Takysie Site A", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/takysie/takysie-site-b":
      images = importAll(require.context("../assets/img/campsites/South/Takysie/Takysie Site B", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/indian-bay":
      images = importAll(require.context("../assets/img/campsites/South/Indian Bay", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/uncha":
      images = importAll(require.context("../assets/img/campsites/South/Uncha", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/binta":
      images = importAll(require.context("../assets/img/campsites/South/Binta", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/binta/binta-north":
      images = importAll(require.context("../assets/img/campsites/South/Binta/Binta North", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/binta/binta-south":
      images = importAll(require.context("../assets/img/campsites/South/Binta/Binta South", false, /\.(png|jpe?g|svg)$/));
      break;

    //Default Case
    default:
      console.log("Invalid category");
  }
  
  //Display Buttons Conditionally for Multiple Categories
  useEffect(() => {
    const categories = ["North", "South", "Binta", "Noralee", "Takysie" ]; 
    if (categories.includes(itemName)) {
      setButtons(true);
    } else {
      setButtons(false);
    }
  }, [item]);

    // Click handler for fullscreen toggle

    const handleImageClick = (e) => {

      const rect = e.currentTarget.getBoundingClientRect();
  
      const x = e.clientX - rect.left;
  
      const width = rect.width;
  
  
  
      if (x >= width * 0.25 && x <= width * 0.75) {
  
        toggleFullscreen();
  
      }
  
    };

  //RETURN STATEMENT
  return (
    <div className={`snapScrolling imagepg ${fullscreen ? "fullscreen" : ""}`}>
        <div>
            <h1>{itemName}</h1>
        </div>
        {buttons ? (
          <Container>
            <Row>
              <Col size={12}>
                <div className="imagepg-buttons">
                  {images.map((image) => {
                    const buttonStyle = {
                      borderRadius: "50%",
                      width: "300px",
                      height: "300px",
                      paddingTop: "130px",
                      margin: "10px",
                      background: `url(${image.file})`,
                      backgroundSize: "cover",
                      border: "none",
                      fontSize: "35px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "white",
                      textShadow: "-1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black, 0px 1px 0px black",
                      transition: "color 0.1s, text-shadow 0.1s",
                    };
                    const buttonHoverStyle = {
                      color: "black",
                      textShadow: "-1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white, 0px 1px 0px white",
                    };
                    return (
                      <Button
                        href={`/Camping/${item}/${image.name.toLowerCase().replace(".jpg","")}`}
                        key={image.name}
                        style={buttonStyle}
                        onMouseEnter={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                        onMouseLeave={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                      >
                        {image.name.replace(".jpg","").replaceAll("-"," ")}
                      </Button>
                    );
                  })}
                </div>
            </Col>
            </Row>
          </Container>
        ) : (
        <div className={'carouselContainer'}>
          <Carousel className={fullscreen ? "fullscreen" : ""} onClick={handleImageClick}>
            {images.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img 
                      className="d-block w-100" 
                      src={image.file} 
                      alt={image.name}
                    />
                  </Carousel.Item>
                )
            })}
          </Carousel>
          {fullscreen ? "" : 
            <div className={'siteInfo snapScrolling'}>
              < CampsiteInfo infoItems={infoItems}/>
              < CampsiteStatus statusItems={statusItems}/>
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default ImagePage;